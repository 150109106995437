// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-template-js": () => import("/opt/build/repo/src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-tags-js": () => import("/opt/build/repo/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-post-js": () => import("/opt/build/repo/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-post-list-js": () => import("/opt/build/repo/src/templates/post-list.js" /* webpackChunkName: "component---src-templates-post-list-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-js": () => import("/opt/build/repo/src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

